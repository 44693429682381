import { Stack, Divider } from '@mui/material';
import { SideBar } from './SideBar';
import { PropsWithChildren } from 'react';
import { Footer } from './Footer';

export const GeneralLayout = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      direction="row"
      width="100%"
      height="100vh"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <SideBar />
      <Stack
        direction="column"
        overflow="auto"
        flex={1}
      >
        {children}
        <Footer/>
      </Stack>
    </Stack>
  );
};
