export const AppRoutes = {
	Dashboard: "/",
	Upload: "/upload",
	Gallery: "/gallery",
	Analytics: "/analytics",
	Settings: "/settings",
	Support: "/support",
	Schedule: "/schedule",
	Status: "/status",
	// NewSchedule: "/new-schedule",
	SelectSchedule: "/select-schedule",
	VideoUpload: "/video-upload",
	Login: "/login",
	Signup: "/signup",
	ForgotPassword: "/forgotpassword",
	VerifyEmail: "/verifyemail",
	ResetPassword: "/resetpassword",
	TermsOfService: "/termsofservice",
	PrivacyPolicy: "/privacypolicy",
};
