import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Stack, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

export const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="start"
    >
      <div style={{paddingTop: "20px", paddingLeft: "20px"}}>
        <IconButton 
          children={
            <ChevronLeft style={{fontSize: '2rem'}}/>
          } 
          onClick={() => navigate(-1)}
        />
      </div>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          ZoneVid Privacy Policy
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          <em>Effective Date: [2024/08/30]</em>
        </Typography>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to ZoneVid. We are committed to protecting your personal information and your right to privacy. This privacy policy describes how we collect, use, and share information about you when you use our app on "zonevid.com" that integrates with TikTok. If you have any questions or concerns about this policy or our practices, please contact us at [redditwarrior200@gmail.com].
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We collect various types of information in connection with the services we provide, including:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>Personal Information:</strong> Information that identifies you as an individual, such as your name, email address, and TikTok account details.
            </li>
            <li>
              <strong>Usage Data:</strong> Information about your interactions with our app, including the content you upload and the scheduling details.
            </li>
            <li>
              <strong>Device Information:</strong> Information about the devices you use to access our services, such as IP address, browser type, and operating system.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use the information we collect for the following purposes:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>Service Delivery:</strong> To provide and maintain our services, including scheduling and posting content to TikTok on your behalf.
            </li>
            <li>
              <strong>Account Management:</strong> To manage your account and authenticate your identity.
            </li>
            <li>
              <strong>Communication:</strong> To communicate with you about your account, provide customer support, and send you updates about our services.
            </li>
            <li>
              <strong>Improvement and Development:</strong> To analyze how our services are used and to improve and develop our app.
            </li>
            <li>
              <strong>Compliance:</strong> To comply with legal obligations and enforce our terms and policies.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            4. How We Share Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We may share your information in the following ways:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>With TikTok:</strong> To facilitate the integration with TikTok, including posting content and managing your TikTok account settings.
            </li>
            <li>
              <strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as cloud storage providers and analytics services.
            </li>
            <li>
              <strong>For Legal Reasons:</strong> We may disclose your information to comply with legal obligations, respond to legal requests, and enforce our terms and policies.
            </li>
            <li>
              <strong>With Your Consent:</strong> We may share your information for other purposes with your explicit consent.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            5. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no security measures are entirely foolproof, and we cannot guarantee the absolute security of your information.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            6. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, resolve disputes, and enforce our agreements.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            7. Your Rights and Choices
          </Typography>
          <Typography variant="body1" paragraph>
            You have certain rights regarding your personal information, including the right to access, update, or delete your information. You can exercise these rights by contacting us at [redditwarrior200@gmail.com].
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            8. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website and indicating the effective date. We encourage you to review this policy periodically.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            9. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this privacy policy or our data practices, please contact us at [redditwarrior200@gmail.com].
          </Typography>
        </Box>
      </Container>
    </Stack>
  );
};
