import { useState } from 'react';
import { AppRoutes } from '../ApplicationRoutes';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Box, TextField, Button } from '@mui/material';
import { useAuth } from '../Contexts/AuthContext';
import { ErrorDialog } from '../Helpers/ErrorDialog';
import { Header } from '../Layouts/Header';

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const { forgotPassword } = useAuth();
	const [emailInput, setEmailInput] = useState('');
	const [error ,setError] = useState<string | null>(null);

	const sendEmail = async () => {
        const sendEmailResult = await forgotPassword(emailInput);
		if(!sendEmailResult){
			navigate(AppRoutes.Login);
		}else{
			setError(sendEmailResult);
		}
	};

	return (
		<Stack
			direction="column"
			width="100%"
			height="100vh"
			justifyContent="center"
			alignItems="center"
			spacing="20px"
		>
			<Header/>
			<Typography
				fontSize="35px"
				fontWeight={
					600
				}
			>
				ZoneVid
			</Typography>
			<Box
				width="400px"
				style={{
					border: '2px solid rgba(0,0,0,0.1)',
					borderRadius: '20px',
					padding: '30px 30px 20px 30px',
				}}
			>
				<Stack
					direction="column"
					width="100%"
					height="100%"
					justifyContent="start"
					alignItems="center"
				>
					<Typography
						fontSize="22px"
						fontWeight={
							600
						}
					>
						Forgot
						Password?
					</Typography>
					<Typography color="grey">
						Please
						enter
						your
						email
					</Typography>
					<TextField
						type="email"
						value={
							emailInput
						}
						onChange={e => {
							setEmailInput(
								e
									.target
									.value
							);
						}}
						label="Email"
						style={{
							marginTop: '30px',
							width: '100%',
						}}
					/>
					<Button
						variant="contained"
						onClick={
							sendEmail
						}
						style={{
							width: '100%',
							height: '40px',
							marginTop: '15px',
							textTransform: 'none',
						}}
					>
						Send password reset link
					</Button>
				</Stack>
			</Box>
			<ErrorDialog
				title="Failed to send password reset link"
				error={error}
				onClose={() => setError(null)}
			/>
		</Stack>
	);
};

export default ForgotPasswordPage;
