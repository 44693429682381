import { useEffect } from "react";
import type { TiktokAccounts } from "./SettingsPage";
import TiktokAccountItem from "./TiktokAccountItem";

interface Props {
	tiktokAccounts: TiktokAccounts[] | null;
	onAccountRemoved: () => void;
}

export default function TikTokAccountList({
	tiktokAccounts,
	onAccountRemoved,
}: Props) {
	useEffect(() => {
		console.log(tiktokAccounts);
	});

	if (!tiktokAccounts) {
		return <></>;
	}

	return (
		<div className="flex flex-col gap-2">
			{tiktokAccounts.map((tiktokAccount) => (
				<TiktokAccountItem
					key={tiktokAccount.id}
					tiktokAccount={tiktokAccount}
					onAccountRemoved={onAccountRemoved}
				/>
			))}
		</div>
	);
}
