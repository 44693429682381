import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
      primary: {
        main:'#194c3eff'
      },
      secondary:{
        main:'#b47e5c'
      },
      text: {
        primary: '#194c3eff',
      }
    },
    typography: {
      h1:{
        color:'#194c3eff'
      },
      h2:{
        color:'#194c3eff'
      },
      h3:{
        color:'#194c3eff'
      },
      h4:{
        color:'#194c3eff'
      },
      h5:{
        color:'#194c3eff'
      },
      h6:{
        color:'#194c3eff'
      }
    },
    components: {
      MuiButton: {
          styleOverrides: {
              root: ({ theme }) => ({
                  '&.MuiButton-containedPrimary:hover': {
                      backgroundColor: theme.palette.secondary,
                  },
                  '&.MuiButton-containedSecondary:hover': {
                      backgroundColor: theme.palette.primary,
                  },
              }),
          },
      },
      MuiTableHead:{
        styleOverrides: {
          root: () => ({
            "& .MuiTableBody-root": {
              backgroundColor: "#121212",
          },
          }),
      },
      }
  }
  })