import { useNavigate } from "react-router";
import { AppRoutes } from "../ApplicationRoutes";
import { Box, Button, Stack, Typography } from "@mui/material";

export const NotFound: React.FC = () => {
    const navigate = useNavigate();
  
    return (
        <Box height="100%">
            <Stack direction="column" height="100vh" width="100%" alignItems="center" justifyContent="center" spacing="10px">
                <Typography fontWeight="bold" fontSize="30px">404 - Not Found</Typography>
                <Typography>The page you are looking for does not exist.</Typography>
                <Button variant="contained" onClick={() => navigate(AppRoutes.Dashboard)}>Go to Homepage</Button>
            </Stack>
        </Box>
    );
  };