import { DeleteForever, EditAttributes } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { type FC, useState } from "react";
import apiClient from "../../ApiClient";
import type { TiktokAccount, Video } from "../Schedule/SchedulePage";
import type { RecurringSchedule } from "./DashboardPage";

interface Props {
	group: string;
	videos: Video[];
	schedule: RecurringSchedule;
	account: TiktokAccount | undefined;
	onDelete: () => void;
}

export const GroupScheduleRow: FC<Props> = ({
	group,
	videos,
	schedule,
	account,
	onDelete,
}) => {
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const scheduleTimes = [
		schedule.post_time_1,
		schedule.post_time_2,
		schedule.post_time_3,
		schedule.post_time_4,
		schedule.post_time_5,
	].filter((t) => t);

	const deleteSchedule = async (id: number) => {
		try {
			await apiClient.delete(`core/recurringschedule/${id}/`);
			onDelete();
		} catch (err) {
			console.error(err);
		} finally {
			setDeleteDialogOpen(false);
		}
	};

	return (
		<>
			<TableRow>
				<TableCell>{group}</TableCell>
				<TableCell align="center">{videos.length}</TableCell>
				<TableCell align="center">
					{videos.filter((v) => v.posted).length}
				</TableCell>
				<TableCell align="center">
					<Tooltip
						title={
							<div>
								{scheduleTimes.map((t) => (
									<div key={t}>
										<span>{t}</span>
										<br />
									</div>
								))}
							</div>
						}
					>
						<Typography>Daily</Typography>
					</Tooltip>
				</TableCell>
				<TableCell align="center">{account?.username ?? "N/A"}</TableCell>
				<TableCell align="center">
					<IconButton
						onClick={() => setDeleteDialogOpen(true)}
						children={<DeleteForever />}
					/>
				</TableCell>
			</TableRow>

			<Dialog
				open={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
			>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					Are you sure you want to delete the schedule for group{" "}
					<strong>{group}</strong>?
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
					<Button onClick={() => deleteSchedule(schedule.id)} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
