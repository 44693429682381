import { Stack, Box, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../ApplicationRoutes';
import { useAuth } from '../Contexts/AuthContext';
import { ErrorDialog } from '../Helpers/ErrorDialog';
import { Header } from '../Layouts/Header';

const SignupPage = () => {
	const navigate = useNavigate();
	const { signup } = useAuth();
	const [emailInput, setEmailInput] = useState('');
	const [usernameInput, setUsernameInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [reenterPasswordInput, setReenterPasswordInput] =
		useState('');
	const [signupComplete, setSignupComplete] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const callSignup = async () => {
		const signupResult = await signup(
			emailInput,
			usernameInput,
			passwordInput,
			reenterPasswordInput
		);
		if (!signupResult) {
			setSignupComplete(true);
		}
		else{
			setError(signupResult);
		}
	};

	const login = () => {
		navigate(AppRoutes.Login);
	};

	return (
		<Stack
			direction="column"
			width="100%"
			height="100vh"
			justifyContent="center"
			alignItems="center"
			spacing="20px"
		>
			<Header/>
			<Typography
				fontSize="35px"
				fontWeight={
					600
				}
			>
				ZoneVid
			</Typography>
			<Box
				width="400px"
				style={{
					border: '2px solid rgba(0,0,0,0.1)',
					borderRadius: '20px',
					padding: '30px 30px 20px 30px',
				}}
			>
				<Stack
					direction="column"
					width="100%"
					height="100%"
					justifyContent="start"
					alignItems="center"
				>
					<Typography
						fontSize="22px"
						fontWeight="bold"
					>
						Sign
						Up
					</Typography>
					<Typography color="grey">
						Let's
						get
						started!
						Fill
						in
						the
						follwing
						information
					</Typography>
					<Stack
						direction="row"
						width="100%"
						justifyContent="center"
						alignItems="center"
						spacing="5px"
						marginTop="30px"
					>
						<TextField
							style={{
								width: '50%',
							}}
							label="Email"
							type="email"
							value={
								emailInput
							}
							onChange={e =>
								setEmailInput(
									e
										.target
										.value
								)
							}
						/>
						<TextField
							style={{
								width: '50%',
							}}
							label="Username"
							type="text"
							value={
								usernameInput
							}
							onChange={e =>
								setUsernameInput(
									e
										.target
										.value
								)
							}
						/>
					</Stack>
					<TextField
						style={{
							marginTop: '10px',
							width: '100%',
						}}
						label="Password"
						type="password"
						value={
							passwordInput
						}
						onChange={e =>
							setPasswordInput(
								e
									.target
									.value
							)
						}
					/>
					<TextField
						style={{
							marginTop: '10px',
							width: '100%',
						}}
						label="Confirm Password"
						type="password"
						value={
							reenterPasswordInput
						}
						onChange={e =>
							setReenterPasswordInput(
								e
									.target
									.value
							)
						}
					/>
					<Button
						variant="contained"
						onClick={
							callSignup
						}
						style={{
							width: '100%',
							height: '40px',
							marginTop: '20px',
							textTransform: 'none',
						}}
					>
						Sign
						Up
					</Button>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						paddingTop="15px"
					>
						<Typography>
							Already
							have
							an
							account?
						</Typography>
						<Button
							onClick={
								login
							}
							style={{
								textTransform: 'none',
							}}
						>
							<Typography>
								Sign
								In
							</Typography>
						</Button>
					</Stack>
				</Stack>
			</Box>
			<Dialog 
				open={signupComplete} 
				onClose={() => {
					setSignupComplete(false);
					login();
				}}
			>
				<DialogTitle>
					Email Verification Sent
				</DialogTitle>
				<DialogContent>
					<Typography>Please verify your email using the link sent to your inbox</Typography>
				</DialogContent>
				<DialogActions>
					<Button 
						variant='contained' 
						onClick={() => {
							setSignupComplete(false);
							login();
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<ErrorDialog
				title="Signup Failed"
				error={error}
				onClose={() => setError(null)}
			/>
		</Stack>
	);
};

export default SignupPage;
