import "./index.css";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppRoutes } from "./ApplicationRoutes";
import { AuthProvider } from "./Contexts/AuthContext";
import { ScheduleContextProvider } from "./Contexts/ScheduleContext";
import { GeneralLayout } from "./Layouts/GeneralLayout";
import { RequireAuth } from "./ProtectedRoute";
import ForgotPasswordPage from "./UserAuthentication/ForgotPasswordPage";
import LoginPage from "./UserAuthentication/LoginPage";
import SignupPage from "./UserAuthentication/SignupPage";
import { AnalyticsPage } from "./pages/AnalyticsPage";
import { DashboardPage } from "./pages/Dashboard/DashboardPage";
import { GalleryPage } from "./pages/Gallery/GalleryPage";
import NewSchedulePage from "./pages/NewSchedule/NewSchedulePage";
import SelectSchedulePage from "./pages/NewSchedule/SelectSchedulePage";
import VideoUploadPage from "./pages/NewSchedule/VideoUploadPage";
import { NotFound } from "./pages/NotFound";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { SettingsPage } from "./pages/Settings/SettingsPage";
import StatusPage from "./pages/Status/Status";
import { SupportPage } from "./pages/SupportPage";
import { TermsOfServicePage } from "./pages/TermsOfServicePage";
import UploadPage from "./pages/Upload/UploadPage";
import { theme } from "./services/Theme";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<BrowserRouter>
					<Routes>
						<Route path={AppRoutes.Login} Component={LoginPage} />
						<Route path={AppRoutes.Signup} Component={SignupPage} />
						<Route
							path={AppRoutes.ForgotPassword}
							Component={ForgotPasswordPage}
						/>
						<Route
							path={AppRoutes.TermsOfService}
							Component={TermsOfServicePage}
						/>
						<Route
							path={AppRoutes.PrivacyPolicy}
							Component={PrivacyPolicyPage}
						/>
						{/* <Route path={AppRoutes.VerifyEmail} Component={VerifyEmailPage} /> */}
						{/* <Route path={AppRoutes.ResetPassword} Component={ResetPasswordPage} /> */}
						<Route
							path={AppRoutes.Dashboard}
							element={
								<RequireAuth>
									<GeneralLayout>
										<DashboardPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Gallery}
							element={
								<RequireAuth>
									<GeneralLayout>
										<GalleryPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Upload}
							element={
								<RequireAuth>
									<GeneralLayout>
										<UploadPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Status}
							element={
								<RequireAuth>
									<GeneralLayout>
										<StatusPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Schedule}
							element={
								<RequireAuth>
									<ScheduleContextProvider>
										<GeneralLayout>
											<NewSchedulePage />
										</GeneralLayout>
									</ScheduleContextProvider>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.SelectSchedule}
							element={
								<RequireAuth>
									<ScheduleContextProvider>
										<GeneralLayout>
											<SelectSchedulePage />
										</GeneralLayout>
									</ScheduleContextProvider>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.VideoUpload}
							element={
								<RequireAuth>
									<ScheduleContextProvider>
										<GeneralLayout>
											<VideoUploadPage />
										</GeneralLayout>
									</ScheduleContextProvider>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Analytics}
							element={
								<RequireAuth>
									<GeneralLayout>
										<AnalyticsPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Support}
							element={
								<RequireAuth>
									<GeneralLayout>
										<SupportPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route
							path={AppRoutes.Settings}
							element={
								<RequireAuth>
									<GeneralLayout>
										<SettingsPage />
									</GeneralLayout>
								</RequireAuth>
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</BrowserRouter>
			</AuthProvider>
		</ThemeProvider>
	);
}

export default App;
