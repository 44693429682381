interface VideoPlayerType {
	videoUrl: string;
}

export default function VideoPlayer({ videoUrl }: VideoPlayerType) {
	return (
		<div>
			<video controls width={"200px"} height={"auto"}>
				<source src={videoUrl} type="video/mp4" />
			</video>
		</div>
	);
}
