import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Link, Stack, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

export const TermsOfServicePage = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="start"
    >
      <div style={{paddingTop: "20px", paddingLeft: "20px"}}>
        <IconButton 
          children={
            <ChevronLeft style={{fontSize: '2rem'}}/>
          } 
          onClick={() => navigate(-1)}
        />
      </div>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          ZoneVid Terms of Service
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          <em>Effective Date: 2024/08/30</em>
        </Typography>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to ZoneVid. These Terms of Service ("Terms") govern your use of our app "zonevid.com" and services that integrate with TikTok to schedule and post content. By accessing or using our services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our services.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            2. Eligibility
          </Typography>
          <Typography variant="body1" paragraph>
            To use our services, you must be at least 13 years old. By using our services, you represent and warrant that you meet this age requirement and that all registration information you submit is accurate and truthful.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            3. Account Registration and Security
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>Account Creation:</strong> You must create an account to use our services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            </li>
            <li>
              <strong>Account Security:</strong> You agree to notify us immediately of any unauthorized use of your account. We are not liable for any loss or damage arising from your failure to safeguard your account information.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            4. User Content and Conduct
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>Content Ownership:</strong> You retain all rights to the content you create and post using our services. By using our services, you grant us a non-exclusive, royalty-free license to use, reproduce, modify, and display your content solely for the purpose of providing our services.
            </li>
            <li>
              <strong>Prohibited Content:</strong> You agree not to post content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable. This includes content that violates TikTok's Community Guidelines or any applicable laws.
            </li>
            <li>
              <strong>Compliance with TikTok Policies:</strong> You agree to comply with all TikTok terms, policies, and guidelines when using our services to post content on TikTok. Any violation of TikTok's policies may result in the suspension or termination of your account.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            5. Use of Services
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>Service Availability:</strong> We strive to provide reliable services, but we do not guarantee that our services will always be available or uninterrupted. We may modify, suspend, or discontinue our services at any time without notice.
            </li>
            <li>
              <strong>Restrictions:</strong> You agree not to use our services for any illegal or unauthorized purpose. You must not access or attempt to access our services by any means other than the interface provided by us.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            6. Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            Your privacy is important to us. Please review our <Link href="/privacypolicy">Privacy Policy</Link> to understand how we collect, use, and share your personal information.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            7. Intellectual Property
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>
              <strong>Our Content:</strong> All content, trademarks, and other intellectual property rights associated with our services are owned by us or our licensors. You agree not to copy, modify, distribute, or create derivative works based on our content without our prior written consent.
            </li>
            <li>
              <strong>Your Feedback:</strong> We welcome your feedback and suggestions. By submitting feedback, you grant us a non-exclusive, royalty-free, perpetual license to use, reproduce, and incorporate your feedback into our services.
            </li>
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            8. Disclaimer of Warranties
          </Typography>
          <Typography variant="body1" paragraph>
            Our services are provided "as is" and "as available" without any warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the maximum extent permitted by law, we are not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use our services; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our services; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; or (e) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via our services.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            10. Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to indemnify, defend, and hold harmless Zone Media Services, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our services, your violation of these Terms, or your infringement of any intellectual property or other rights of any person or entity.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            11. Governing Law and Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms are governed by and construed in accordance with the laws of UK, without regard to its conflict of law principles. Any disputes arising under or in connection with these Terms shall be resolved through binding arbitration in [UK] in accordance with the rules, except that you may assert claims in small claims court if your claims qualify.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            12. Changes to These Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may update these Terms from time to time. If we make material changes, we will notify you by email or through our services before the changes take effect. Your continued use of our services after the changes become effective means you agree to the revised Terms.
          </Typography>
        </Box>

        <Box component="section" sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            13. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about these Terms, please contact us at [redditwarrior200@gmail.com].
          </Typography>
        </Box>
      </Container>
    </Stack>
  );
};
