import type React from "react";
import { Box, Typography, Grid } from "@mui/material";
import type { CreatorInfoType } from "./VideoUploadPage";

interface CreatorInfoDisplayProps {
	creatorInfo: CreatorInfoType | null;
}

const CreatorInfoDisplay: React.FC<CreatorInfoDisplayProps> = ({
	creatorInfo,
}) => {
	return (
		<Box sx={{ mt: 2, mb: 4 }}>
			<Typography variant="h6" gutterBottom>
				Creator Information
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1">
						Nickname: {creatorInfo?.creator_nickname}
					</Typography>
					<Typography variant="subtitle1">
						Username: {creatorInfo?.creator_username}
					</Typography>
					<Typography variant="subtitle1">
						Max Video Duration: {creatorInfo?.max_video_post_duration_sec}{" "}
						seconds
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1">
						Duet Disabled: {creatorInfo?.duet_disabled ? "Yes" : "No"}
					</Typography>
					<Typography variant="subtitle1">
						Stitch Disabled: {creatorInfo?.stitch_disabled ? "Yes" : "No"}
					</Typography>
					<Typography variant="subtitle1">
						Comments Disabled: {creatorInfo?.comment_disabled ? "Yes" : "No"}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CreatorInfoDisplay;
