import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { useScheduleContext } from "../../Contexts/ScheduleContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type { Dayjs } from "dayjs";

export default function SchedulePostInput({ id }: { id: number }) {
	const { setPostTimes } = useScheduleContext();

	const handleTimeInput = (date: Dayjs | null) => {
		if (date) {
			setPostTimes((prev) => {
				const newPostTimes = [...prev];
				newPostTimes[id] = date;
				return newPostTimes;
			});
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<TimePicker sx={{ width: "200px" }} onChange={handleTimeInput} />
		</LocalizationProvider>
	);
}
