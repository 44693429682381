import { Stack, Button } from "@mui/material"
import { useNavigate } from "react-router"
import { AppRoutes } from "../ApplicationRoutes";

export const Header = () => {
    const navigate = useNavigate();
    return (
        <Stack 
            direction="row"
            alignItems="center" 
            position="absolute" 
            top={10} 
            right={10}
        >
            <Button 
                style={{textTransform: "none"}}
                onClick={() => navigate(AppRoutes.TermsOfService)}
            >
                Terms of Services
            </Button>
            <Button 
                style={{textTransform: "none"}}
                onClick={() => navigate(AppRoutes.PrivacyPolicy)}
            >
                Privacy Policy
            </Button>
        </Stack>
    )
}