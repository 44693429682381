import type React from "react";
import { Snackbar, Alert, type AlertColor } from "@mui/material";

interface NotificationProps {
	open: boolean;
	message: string;
	severity: AlertColor;
	onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({
	open,
	message,
	severity,
	onClose,
}) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default Notification;
