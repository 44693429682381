import { useNavigate } from "react-router";
import { useScheduleContext } from "../../Contexts/ScheduleContext";
import { AppRoutes } from "../../ApplicationRoutes";
import { useEffect, useState } from "react";
import {
	Button,
	Divider,
	MenuItem,
	Select,
	type SelectChangeEvent,
	Stack,
	Typography,
} from "@mui/material";
import apiClient from "../../ApiClient";
import { Schedule } from "@mui/icons-material";
import SchedulePostInput from "./SchedulePostInput";
import type { TiktokAccount } from "../Schedule/SchedulePage";

export default function SelectSchedulePage() {
	const [tiktokAccounts, setTiktokAccounts] = useState<TiktokAccount[]>([]);
	const [scheduleButtonCounter, setScheduleButtonCounter] = useState<number>(0);
	const { selectedGroup, selectedTiktokAccount, setSelectedTiktokAccount } =
		useScheduleContext();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				if (selectedGroup.length === 0) {
					navigate(AppRoutes.Schedule);
					return;
				}
				await fetchTiktokAccounts();
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [selectedGroup, navigate]);

	const handleAddScheduleClick = () => {
		if (scheduleButtonCounter === 5) return;
		setScheduleButtonCounter((prev) => prev + 1);
	};

	const fetchTiktokAccounts = async () => {
		const response = await apiClient.get("/core/tiktokaccounts/");
		const data = response.data as TiktokAccount[];
		setTiktokAccounts(data);
	};

	const handleTiktokAccountSelect = (event: SelectChangeEvent<string>) => {
		const id = Number.parseInt(event.target.value, 10);
		const selectedAccount = tiktokAccounts.find((account) => account.id === id);
		if (selectedAccount) {
			setSelectedTiktokAccount(selectedAccount);
		}
	};

	return (
		<Stack
			direction={"column"}
			flex={1}
			divider={<Divider orientation="horizontal" flexItem />}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				paddingX={"20px"}
				paddingY={"20px"}
			>
				<Stack direction={"column"}>
					<Typography fontSize={"25px"} fontWeight={"bold"}>
						New Schedule
					</Typography>
				</Stack>
			</Stack>
			<Stack
				direction={"column"}
				justifyContent={"space-between"}
				paddingX={"20px"}
				paddingY={"20px"}
				gap={5}
			>
				<div className="tiktok-account flex flex-col gap-4">
					<div>
						<h1 className="font-semibold text-xl text-neutral-700">
							Select TikTok account
						</h1>
						<p className="text-sm text-neutral-500">
							Choose a Tiktok account to post your video group to
						</p>
					</div>
					<Select
						id="tiktok-account-select"
						value={
							selectedTiktokAccount ? String(selectedTiktokAccount.id) : ""
						}
						sx={{
							minWidth: 200,
							maxWidth: 300,
						}}
						disabled={isLoading}
						onChange={handleTiktokAccountSelect}
					>
						{tiktokAccounts.length > 0 ? (
							tiktokAccounts.map((account) => (
								<MenuItem key={account.id} value={String(account.id)}>
									{account.username}
								</MenuItem>
							))
						) : (
							<MenuItem disabled value="">
								No TikTok accounts available
							</MenuItem>
						)}
					</Select>
				</div>
				<div className="flex flex-col gap-4">
					<div>
						<h1 className="font-semibold text-xl text-neutral-700">
							Add video schedule times
						</h1>
						<p className="text-sm text-neutral-500">
							You may set up to 5 different times
						</p>
					</div>

					{[...Array(scheduleButtonCounter)].map((_, i) => (
						<SchedulePostInput key={i} id={i} />
					))}

					<Button
						variant="outlined"
						startIcon={<Schedule />}
						onClick={handleAddScheduleClick}
						sx={{
							width: "fit-content",
						}}
					>
						Add Schedule
					</Button>
					<Button
						onClick={() => navigate("/video-upload")}
						sx={{
							width: "fit-content",
						}}
						variant="outlined"
					>
						Next
					</Button>
				</div>
			</Stack>
		</Stack>
	);
}
