import type { Dayjs } from "dayjs";
import { createContext, useContext, useState } from "react";
import type { TiktokAccount } from "../pages/Schedule/SchedulePage";

type ScheduleContextDataType = {
	selectedTiktokAccount: TiktokAccount | null;
	setSelectedTiktokAccount: React.Dispatch<
		React.SetStateAction<TiktokAccount | null>
	>;
	selectedGroup: string;
	setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
	postTimes: Dayjs[];
	setPostTimes: React.Dispatch<React.SetStateAction<Dayjs[]>>;
};

const ScheduleContext = createContext<ScheduleContextDataType | undefined>(
	undefined,
);

export const useScheduleContext = () => {
	const context = useContext(ScheduleContext);
	if (context === undefined)
		throw new Error(
			"useScheduleContext must be used inside the ScheduleContext Provider",
		);
	return context;
};

export const ScheduleContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [selectedGroup, setSelectedGroup] = useState<string>("");
	const [selectedTiktokAccount, setSelectedTiktokAccount] =
		useState<TiktokAccount | null>(null);
	const [postTimes, setPostTimes] = useState<Dayjs[]>(new Array(5).fill(null));

	const scheduleContextData: ScheduleContextDataType = {
		selectedTiktokAccount,
		setSelectedTiktokAccount,
		selectedGroup,
		setSelectedGroup,
		postTimes,
		setPostTimes,
	};

	return (
		<ScheduleContext.Provider value={scheduleContextData}>
			{children}
		</ScheduleContext.Provider>
	);
};
