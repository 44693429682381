import { Stack, Divider, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"
import { ErrorDialog } from "../../Helpers/ErrorDialog";
import { useState } from "react";
import { AppRoutes } from "../../ApplicationRoutes";

export const GalleryPage = () => {
    const navigate = useNavigate();

    const [error, setError] = useState<string | null>(null);

    return (
        <Stack
			direction="column"
			flex={1}
			divider={
				<Divider
					orientation="horizontal"
					flexItem
				/>
			}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				paddingX="20px"
				paddingY="20px"
			>
				<Typography
					fontSize="25px"
					fontWeight="bold"
				>
					Gallery
				</Typography>
				<Button
						variant="contained"
						style={{
							textTransform:
								'none',
						}}
						onClick={() => {
							navigate(
								AppRoutes.Upload
							);
						}}
					>
						Upload Group
					</Button>
			</Stack>
			<ErrorDialog
				title="Failed to fetch posts"
				error={error}
				onClose={() => setError(null)}
			/>
		</Stack>
    )
}