import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { PropsWithChildren } from 'react';

interface Props {
  title: string;
  stat: number;
}

export const StatsBox: FC<PropsWithChildren<Props>> = ({
  title,
  stat,
  children,
}) => {
  return (
    <Box
      border="1px solid rgba(0,0,0,0.1)"
      borderRadius="10px"
      paddingY="15px"
      paddingX="20px"
      width="100%"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        height="100%"
        spacing="10px"
      >
        <Stack direction="row" width="100%" alignItems="center" spacing="5px">
          {children}
          <Typography color="grey" fontSize="14px" fontWeight="600">
            {title}
          </Typography>
        </Stack>
        <Typography fontWeight="bold" fontSize="30px">
          {stat}
        </Typography>
      </Stack>
    </Box>
  );
};
