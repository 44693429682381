import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
    title: string | null;
    error: string | null;
    onClose: () => void;
}

export const ErrorDialog:FC<Props> = ({title, error, onClose}) => {
    return (
        <Dialog 
            open={error != null && title != null} 
            onClose={onClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography>{error}</Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                    style={{textTransform: 'none'}}
                    variant='contained' 
                    onClick={onClose}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}