import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import apiClient from "../../ApiClient";
import { AppRoutes } from "../../ApplicationRoutes";
import { useScheduleContext } from "../../Contexts/ScheduleContext";
import type { Video } from "../Schedule/SchedulePage";
import CreatorInfoDisplay from "./CreatorInfoDisplay";
import Notification from "./Notification";
import VideoPlayer from "./VideoPlayer";

export type CreatorInfoType = {
	duet_disabled: boolean;
	max_video_post_duration_sec: number;
	privacy_level_options: string[];
	stitch_disabled: boolean;
	comment_disabled: boolean;
	creator_avatar_url: string;
	creator_nickname: string;
	creator_username: string;
};

// type CreatorInfoEndpointType = {
// 	data: CreatorInfoType;
// 	error: {
// 		code: string;
// 		message: string;
// 		log_id: string;
// 	};
// };

type NotificationType = {
	open: boolean;
	message: string;
	severity: "success" | "error" | "info" | "warning";
};

const defaultNotificationState: NotificationType = {
	open: false,
	message: "",
	severity: "info",
};

const MOCK_CREATOR_INFO: CreatorInfoType = {
	duet_disabled: true,
	max_video_post_duration_sec: 600,
	privacy_level_options: [
		"FOLLOWER_OF_CREATOR",
		"MUTUAL_FOLLOW_FRIENDS",
		"SELF_ONLY",
	],
	stitch_disabled: true,
	comment_disabled: false,
	creator_avatar_url: "https://image.com",
	creator_nickname: "hmbakhsh",
	creator_username: "hmbakhsh",
};

export default function VideoUploadPage() {
	const { selectedTiktokAccount, selectedGroup, postTimes } =
		useScheduleContext();
	const [videos, setVideos] = useState<Video[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
	const [creatorInfo, setCreatorInfo] = useState<CreatorInfoType | null>(
		MOCK_CREATOR_INFO,
	);
	const [editingVideo, setEditingVideo] = useState<Video | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [notification, setNotification] = useState<NotificationType>(
		defaultNotificationState,
	);
	const navigate = useNavigate();

	const handleOpenModal = (video: Video) => {
		setSelectedVideo(video);
		setEditingVideo({ ...video });
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setSelectedVideo(null);
		setEditingVideo(null);
		setOpenModal(false);
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		if (editingVideo) {
			setEditingVideo({
				...editingVideo,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (editingVideo) {
			setEditingVideo({
				...editingVideo,
				[e.target.name]: e.target.checked,
			});
		}
	};

	const handleSelectChange = (e: SelectChangeEvent<string>) => {
		if (editingVideo) {
			setEditingVideo({
				...editingVideo,
				[e.target.name as string]: e.target.value,
			});
		}
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			for (const video of videos) {
				await submitVideoMetadata(video.id);
			}
			await postSchedule();

			setNotification({
				open: true,
				message: "Schedule submitted successfully!",
				severity: "success",
			});
		} catch (error) {
			console.error("Error submitting schedule:", error);
			setNotification({
				open: true,
				message: "Failed to submit schedule. Please try again.",
				severity: "error",
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	// Must be tested in production -> currently receiving error: "access_token_invalid"
	// The access token is invalid or not found in the request
	const fetchCreatorInfo = useCallback(async () => {
		try {
			const res = await apiClient.post("auth/tiktok/creator-info", {
				tiktok_account: selectedTiktokAccount?.id,
			});
			setCreatorInfo(res.data.data);
		} catch (err) {
			console.error("Error fetching creator info");
			setNotification({
				open: true,
				message: "Failed to fetch creator info",
				severity: "error",
			});
		}
	}, [selectedTiktokAccount?.id]);

	const fetchVideos = useCallback(async () => {
		try {
			apiClient
				.get("/core/videos/")
				.then((res) => res.data as Video[])
				.then((data) => data.filter((v) => v.group === selectedGroup))
				.then((filteredVideos) => setVideos(filteredVideos));
		} catch (err) {
			console.error(err);
		}
	}, [selectedGroup]);

	const createPostTimeObject = () => {
		const filteredPostTimes = postTimes.filter(
			(time): time is Dayjs => time !== null,
		);
		return filteredPostTimes.reduce(
			(acc, time, index) => {
				acc[`post_time_${index + 1}`] = time.format("HH:mm:ss");
				return acc;
			},
			{} as Record<string, string>,
		);
	};

	const postSchedule = async () => {
		const postTimeObject = createPostTimeObject();
		const requestBody = {
			group: selectedGroup,
			tiktok_account: selectedTiktokAccount?.id,
			...postTimeObject,
		};
		try {
			await apiClient.post("core/recurringschedule/", requestBody);
		} catch (error) {
			console.error("Error creating recurring schedule:", error);
			throw error;
		}
	};

	const handleUpdateVideo = async () => {
		if (!editingVideo) return;

		try {
			await submitVideoMetadata(editingVideo.id);

			setVideos(
				videos.map((v) => (v.id === editingVideo.id ? editingVideo : v)),
			);

			setNotification({
				open: true,
				message: "Video updated successfully!",
				severity: "success",
			});
			handleCloseModal();
		} catch (error) {
			console.error("Error updating video:", error);
			setNotification({
				open: true,
				message: "Failed to update video. Please try again.",
				severity: "error",
			});
		}
	};

	const submitVideoMetadata = async (videoId: number) => {
		try {
			const videoToUpdate = videos.find((v) => v.id === videoId);
			if (!videoToUpdate) throw new Error("Video not found");
			const { video, ...videoDataToSubmit } = videoToUpdate;
			await apiClient.patch(`core/videos/${videoId}/`, videoDataToSubmit);
		} catch (error) {
			console.error(`Error updating video ${videoId}:`, error);
			throw error;
		}
	};

	useEffect(() => {
		if (selectedGroup.length === 0) {
			navigate(AppRoutes.Schedule);
			return;
		}
		fetchCreatorInfo();
		fetchVideos();
	}, [
		selectedGroup,
		selectedTiktokAccount,
		navigate,
		fetchCreatorInfo,
		fetchVideos,
	]);

	const handleCloseNotification = () => {
		setNotification({ ...notification, open: false });
	};

	return (
		<Stack
			direction={"column"}
			flex={1}
			divider={<Divider orientation="horizontal" flexItem />}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				paddingX={"20px"}
				paddingY={"20px"}
			>
				<Stack direction={"column"}>
					<Typography fontSize={"25px"} fontWeight={"bold"}>
						Upload Videos
					</Typography>
				</Stack>
			</Stack>
			<Stack
				direction={"column"}
				justifyContent={"space-between"}
				paddingX={"20px"}
				paddingY={"20px"}
				gap={5}
			>
				{/* <Typography fontSize={"15px"} fontWeight={"bold"}>
					{selectedTiktokAccount?.username} Creator Info
				</Typography> */}
				<CreatorInfoDisplay creatorInfo={creatorInfo} />
				<TableContainer>
					<Table
						sx={{
							minWidth: 650,
						}}
					>
						<TableHead>
							<TableCell>Video Preview</TableCell>
							<TableCell>Video Name</TableCell>
							<TableCell>Edit Video Form</TableCell>
						</TableHead>
						<TableBody>
							{videos?.map((video) => (
								<TableRow key={video.id}>
									<TableCell>
										<VideoPlayer videoUrl={video.video} />
									</TableCell>
									<TableCell
										align="left"
										style={{
											verticalAlign: "top",
										}}
									>
										<Typography fontSize={"15px"} fontWeight={"bold"}>
											{video.title}
										</Typography>
									</TableCell>
									<TableCell
										align="left"
										style={{
											verticalAlign: "top",
										}}
									>
										<Button
											variant="contained"
											color="primary"
											onClick={() => handleOpenModal(video)}
										>
											Edit Video Form
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Modal
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby="edit-video-modal"
				>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: 400,
							bgcolor: "background.paper",
							boxShadow: 24,
							p: 4,
							maxHeight: "90vh",
							overflowY: "auto",
						}}
					>
						<h2 id="edit-video-modal">Edit Video: {selectedVideo?.title}</h2>
						{editingVideo && (
							<form
								onSubmit={(e) => {
									e.preventDefault();
									handleUpdateVideo();
								}}
							>
								<TextField
									fullWidth
									label="Video Title"
									name="title"
									value={editingVideo.title}
									onChange={handleInputChange}
									margin="normal"
								/>
								<TextField
									fullWidth
									label="Description"
									name="description"
									value={editingVideo.description}
									onChange={handleInputChange}
									margin="normal"
									multiline
									rows={4}
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="privacy-level-label">
										Privacy Level
									</InputLabel>
									<Select
										labelId="privacy-level-label"
										name="privacy_level"
										value={editingVideo.privacy_level}
										onChange={handleSelectChange}
									>
										{creatorInfo?.privacy_level_options.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControlLabel
									control={
										<Switch
											checked={editingVideo.comment_disabled}
											disabled={creatorInfo?.comment_disabled}
											onChange={handleSwitchChange}
											name="comment_disabled"
										/>
									}
									label="Disable Comments"
								/>
								<FormControlLabel
									control={
										<Switch
											checked={editingVideo.duet_disabled}
											onChange={handleSwitchChange}
											name="duet_disabled"
											disabled={creatorInfo?.duet_disabled}
										/>
									}
									label="Disable Duets"
								/>
								<FormControlLabel
									control={
										<Switch
											checked={editingVideo.stitch_disabled}
											onChange={handleSwitchChange}
											name="stitch_disabled"
											disabled={creatorInfo?.stitch_disabled}
										/>
									}
									label="Disable Stitches"
								/>
								<Box sx={{ mb: 1 }}>
									<FormControlLabel
										control={
											<Switch
												checked={editingVideo.disclose_video_content}
												onChange={handleSwitchChange}
												name="disclose_video_content"
											/>
										}
										label="Disclose Video Content"
									/>
									{editingVideo.disclose_video_content && (
										<Typography
											variant="caption"
											color="text.primary"
											sx={{
												display: "block",
												ml: 2,
												mt: 0.5,
											}}
										>
											Your video will be labeled "Promotional content". This
											cannot be changed once your video is posted.
										</Typography>
									)}
									<Typography
										variant="caption"
										color="text.secondary"
										sx={{
											display: "block",
											ml: 2,
											mt: 0.5,
										}}
									>
										Turn on to disclose that this video promotes goods or
										services in exchange for something of value. Your video
										could promote yourself, a third party of both.
									</Typography>
								</Box>
								{editingVideo.disclose_video_content && (
									<Box sx={{ mb: 3 }}>
										<FormControlLabel
											control={
												<Switch
													checked={editingVideo.your_brand}
													onChange={handleSwitchChange}
													name="your_brand"
												/>
											}
											label="Your Brand"
										/>
										<Typography
											variant="caption"
											color="text.secondary"
											sx={{
												display: "block",
												ml: 2,
												mt: 0.5,
											}}
										>
											Your are promoting yourself or your own business. This
											video will be classified as Brand Organic.
										</Typography>
										<FormControlLabel
											control={
												<Switch
													checked={editingVideo.branded_content}
													onChange={handleSwitchChange}
													name="branded_content"
												/>
											}
											label="Branded Content"
										/>
										<Typography
											variant="caption"
											color="text.secondary"
											sx={{
												display: "block",
												ml: 2,
												mt: 0.5,
											}}
										>
											You are promoting another brand or a third party. This
											video will be classified as Branded Content.
										</Typography>
										<Typography
											variant="caption"
											color="text.secondary"
											sx={{
												display: "block",
												ml: 2,
												mt: 0.5,
											}}
										>
											By posting, you agree to Tiktok's{" "}
											<a
												className="text-blue-500"
												href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
											>
												Music Usage Confirmation
											</a>
											.
										</Typography>
									</Box>
								)}
								<div>
									<Button onClick={handleCloseModal}>Cancel</Button>
									<Button type="submit">Update Video</Button>
								</div>
							</form>
						)}
					</Box>
				</Modal>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					disabled={isSubmitting}
					sx={{ mt: 2 }}
				>
					{isSubmitting ? <CircularProgress size={24} /> : "Submit Schedule"}
				</Button>
				<Notification
					open={notification.open}
					message={notification.message}
					severity={notification.severity}
					onClose={handleCloseNotification}
				/>
			</Stack>
		</Stack>
	);
}
